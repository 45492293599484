import React from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faEnvelopeOpen } from "@fortawesome/free-solid-svg-icons";
import cartoon from "../img/lumberjack-fotor-2023092083510.png";
import Separator from "./separator";

library.add(faEnvelopeOpen);

class Contact extends React.Component {
  render() {
    return (
      <section
        className="paralax-mf footer-paralax bg-image sect-mt4 route"
        style={{ backgroundColor: "white", marginTop: 0 }}
        id="contact"
      >
        <Separator title={"Kontakt"} downwardsLeft={false} />
        <div className="overlay-mf"></div>
        <div className="row">
          <div className="col-sm-12">
            <div className="contact-mf">
              <div id="contact" style={{ paddingBottom: 0 }}>
                <div
                  className="row contact"
                  style={{
                    maxWidth: "1200px",
                    margin: "0 auto",
                    alignItems: "center",
                  }}
                >
                  <div className="col-md-6">
                    <div className="title-box-2 pt-4 pt-md-0">
                      <h5 className="title-left">
                      Kontakta mig så pratar vi vidare om du har några fler frågor eller vill lägga en beställning!
                      </h5>
                    </div>
                    <div className="more-info">
                      <p className="lead"></p>
                      {
                        <ul class="list-ico">
                          <li>
                            <a
                              href="tel: +46 705 23 33 81"
                              onClick={() =>
                                window.dataLayer.push({
                                  event: "Contact Click",
                                  analyticsInfo: "Phone",
                                })
                              }
                            >
                              Telefon: +(46) 76 007 31 45
                            </a>
                          </li>
                          <li>
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href="mailto: kicki.svensson@kicsve.se"
                              onClick={() =>
                                window.dataLayer.push({
                                  event: "Contact Click",
                                  analyticsInfo: "Mail",
                                })
                              }
                            >
                              Mail: vickes@outlook.com
                            </a>
                          </li>
                          
                          <li>
                            <span style={{ color: "black" }}>
                              Viktor's Ved, Nedre Avelsås 104, 52399 Hökerum
                            </span>
                          </li>
                          
                        </ul>
                      }
                    </div>
                    <div className="socials"></div>
                  </div>
                  <div className="col-md-6">
                    <img
                      src={cartoon}
                      style={{ maxHeight: 500, maxWidth: "80vw" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer>
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="copyright-box"></div>
              </div>
            </div>
          </div>
        </footer>
      </section>
    );
  }
}

export default Contact;
