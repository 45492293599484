import { library } from "@fortawesome/fontawesome-svg-core";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import styled from "@emotion/styled";
import etonLogo from "../img/eton.png";

library.add(faUser);

const Grid = styled.div`
  grid-area: kicksve;
  display: grid;
  align-items: center;
  justify-items: center;
  background-color: white;
  grid-template-areas:
    "pic"
    "text";

  @media (min-width: 1200px) {
    display: grid;
    align-items: left;
    justify-items: left;
    grid-auto-columns: 1fr;
    grid-template-areas: "pic text";
  }
`;

const Text = styled.div`
  grid-area: text;
  padding-bottom: 80px;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 1200px;

  @media (min-width: 768px) {
    padding-left: 80px;
    padding-right: 120px;
    min-width: 780px;
  }
`;

const PictureContainer = styled.div`
  grid-area: pic;
  width: 100%;
  object-fit: cover;
  height: 100%;
`;

const Picture = styled.img`
  grid-area: pic;
  width: 100%;
  object-fit: cover;
  height: 100%;
`;

const Wrapper = styled.div`
  padding-bottom: 80px;
  
background: rgb(9,56,42);
background: linear-gradient(90deg, rgba(9,56,42,1) 25%, rgba(2,32,24,1) 74%);
  opacity: 0.96;
`;

const Title = styled.div`
  font-size: 50px;
  font-weight: 700;
  padding-top: 54px;
  line-height: 1em;
  color: white;
  padding-left: 20px;
  padding-bottom: 20px;
  text-align: center;
`;

const FullWidthText = styled.div`
  font-weight: 400;
  font-size: 20px;
  color: white;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  line-height: 28px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;

  @media (min-width: 1200px) {
    flex-direction: row;
  }
`;

const ColumnText = styled.div`
  color: white;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  width: 100%;
  padding: 0px 10px;

  @media (min-width: 1200px) {
    width: 50%;
    float: left;
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const Inner = styled.div`
  justify-content: center;
  padding: 0px 10px;

  @media (min-width: 1200px) {
    max-width: 700px;
    margin-top: 40px;
    padding: 0px 50px;
  }
`;

const SingleTextLeft = styled(Inner)`
  @media (min-width: 1200px) {
    float: right;
  }
`;

const SingleTextRight = styled(Inner)`
  @media (min-width: 1200px) {
    float: left;
    padding-bottom: 20px;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;
`;

const AboutKickSve = () => {
  return (
    <section id="about">
      <Wrapper>
        <Title>Om Viktors Ved</Title>

        <FullWidthText>
          <ColumnText>
            <SingleTextLeft>
              <p>
              Hej,
Det är jag som är Viktor, en 15-årig entreprenör från Grovare, strax utanför Ulricehamn.

                
              </p>
              <p>
              Jag brinner för att fixa grym ved till mina kunder - året om när det finns i lager. 
                
                
              </p>
              <p>
              Allt började med en ganska simpel idé- att se till att alla runt omkring Ulricehamn, eller längre bort med för den delen,
              skulle kunna njuta av torkad ved för att göra vintermyset och kalla höstdagar lite behagligare, trevligare och framförallt varmare.
              </p>
            </SingleTextLeft>
          </ColumnText>
          <ColumnText>
            <SingleTextRight>
              <p>
              Min idé om verksamheten bygger på ett 'pantsystem' där du som kund betalar 750 kronor för veden och 150 kronor för säcken.
              Om du sen lämnar säcken i schysst skick, får du tillbaka dina 150 kronor som du betalat för säcken. Bra deal, visst!?
              </p>
              <p>
              Jag är stolt över att kunna säga att jag erbjuder ved tillverkad av det finaste virket där jag själv noggrant klyver veden för att säkerställa maximal värme och ved av finaste kvalité. En extra bonus är att veden är snäll mot naturen där det inte finns några kemikalier eller föroreningar. 
Allt självklart närproducerat från vår egen mark!

              </p>
              {/*<ImageContainer>
                <img src={etonLogo} alt="logo" style={{ maxWidth: "200px" }} />
              </ImageContainer>*/}
              <p>Jag ser fram emot att träffa och höra vidare från er kunder!
</p>
              <p>Viktor Svensson</p>
            </SingleTextRight>
          </ColumnText>
        </FullWidthText>
      </Wrapper>
    </section>
  );
};

/* const AboutKickSve = () =>  {
    return (
      <section id="about" className="about-mf sect-pt4 route about-container">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="row">
                <div className="col-md-6">
                  <div className="skill-mf">
                    <h5 className="title-left">Verktygslåda</h5>
                    <ul style={{ marginTop: 55 }}>
                      <li>Strategi & Mål</li>
                      <li> Digital Strategi </li>
                      <li>Inköps- & Försäljningsbudget & Prognos</li>
                      <li>Analys & Digital Analys</li>
                      <li>Sortimentsstrategi & Sortimentsplanering</li>
                      <li>Merchandising online & butik</li>
                      <li>Inköpsplanering & Lagerstyrning</li>
                      <li>Varustyrning</li>
                      <li>System- & Verksamhetsutveckling</li>
                      <li>Agila processer & förhållningssätt</li>
                      <li>Digital transformation</li>
                      <li>Förändringsledarskap & Projektledning</li>
                      <li>
                        Excel, Google Analytics, QlickView, Power BI, DaVinci,
                        JDA, IFS, SAP, Magento
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="about-me pt-4 pt-md-0">
                    <div className="title-box-2">
                      <h5 className="title-left">Om mig</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
} */

export default AboutKickSve;
