import { library } from "@fortawesome/fontawesome-svg-core";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import styled from "@emotion/styled";
// import myImage from "../img/myImage.png";
import inzoomadved from "../img/vedsäck2.jpg";

import vedmaskin from "../img/IMG-4674.jpg";

import ved from "../img/IMG-4668[5914].jpg";



library.add(faUser);

const Grid = styled.div`
  grid-area: kicksve;
  display: grid;
  align-items: center;
  justify-items: center;
  background-color: white;
  grid-template-areas:
    "pic pic pic"
    "text text text";

  @media (min-width: 1200px) {
    display: grid;
    align-items: left;
    justify-items: left;
    grid-auto-columns: 1fr;
    grid-template-areas: "pic text";
  }
`;

const Text = styled.div`
  grid-area: text;
  padding-bottom: 80px;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 1200px;

  @media (min-width: 768px) {
    padding-left: 80px;
    padding-right: 120px;
  }
`;

const PictureContainer = styled.div`
  grid-area: pic;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height:100%
`;

const Picture = styled.img`
  width: 100%;
  object-fit: cover;
  height:70%
`;

const Wrapper = styled.div`
  padding-bottom: 50px;
  background-color: #2f5597;
`;

const Title = styled.div`
  font-size: 25px;
  font-weight: 700;
  padding-top: 54px;
  line-height: 1em;
  color: white;
  padding-left: 20px;
  padding-bottom: 20px;
  text-align: center;
  background-color: #2f5597;
`;

const Vision = () => {
  return (
    <section id="vision">
      <Grid>
        <PictureContainer>
          <Picture src={inzoomadved} alt="logo" />
          <Picture src={vedmaskin} alt="logo" />
          <Picture src={ved} alt="logo" />
        </PictureContainer>
      </Grid>
    </section>
  );
};

export default Vision;
