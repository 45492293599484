import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faUniversity,
  faChartBar,
  faGlobeEurope,
  faRulerCombined,
} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import styled from "@emotion/styled";
import sweden from "../img/sweden.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Separator from "./separator";

library.add([faUniversity, faChartBar, faGlobeEurope, faRulerCombined]);
const Li = styled.li`
list-style-type: none; 
`;

const Ul = styled.ul`
padding: 0; 
`;
const offerings = [
  {
    title: "Prisklasser",
    description: (
      <>
      <p>Nedan är priset per kubik. Man kan helt och hållet välja hur många kubik man vill köpa men jag säljer bara hela säckar (en kubik i respektive).</p>
    <Ul> 

<Li>1 kubik - 750kr</Li>

<Li> 2 kubik - 1500kr</Li>

<Li> 3 kubik - 2250kr</Li>

<Li> 4 kubik - 3000kr</Li>

<Li> 5 kubik - 3750kr</Li>

<Li> 6 kubik - 4500kr</Li>

<Li> 7 kubik - 5250kr</Li>

<Li> 8 kubik - 6000kr</Li>

<Li> 9 kubik - 6750kr</Li>

<Li> 10 kubik - 7500kr</Li> 


</Ul>

        <p> </p>
        <p>

        </p>
        <p>
          
        </p>
        <p>
          
        </p>
        {/*<ul style={{ marginTop: 10, textAlign: "left" }}>
          <li>Strategi & Mål</li>
          <li>E-Commerce & Digital Strategi</li>
          <li>Analys & Rapportering</li>
          <li>Försäljningsbudget & Prognos</li>
          <li>Inköpsbudget</li>
          <li>Excel, Google Analytics, QlickView, Power BI</li>
      </ul>*/}
      </>
    ),
    icon: "pricing",
  },
 
  {
    title: "Aktuella Erbjudanden",
    description: (
      <>
      <p>Just nu har jag olika erbjudanden för dig som vill bli, eller redan är kund hos mig på Viktors Ved.</p>
       <Ul>
<Li>Rabbat: Vid köp av 5 säckar får du 10%rabatt på hela köpet.</Li>
<Li>Hemleverans: Hemkörning vid köp av 10 säckar till er som bor i Hökerums bygden. Priset för hemkörning förhandlas beroende på var du bor.</Li>
<Li>Säsongsstartserbjudande: Vid köp av 4 säckar ved (ett värde av 2,600kr) får du en gratis bunt tändstickor på köpet.</Li>
<Li>Dubbla ditt nöje" erbjudande: Köp 2 säckar ved för endast 1300 kr istället för 1400 kr.</Li>







       </Ul>
        <p></p>
        <p>
          
        </p>
        <p>
         
        </p>
        {/*<ul style={{ marginTop: 10, textAlign: "left" }}>
          <li>Sortimentsstrategi</li>
          <li>Sortimentsplanering</li>
          <li>Kampanjplan</li>
          <li>Inköpsplanering & Förpackningsoptimering</li>
          <li>Allokering & Lageroptimering</li>
          <li>Merchandising butik & online</li>
          <li>Excel, DaVinci, JDA, IFS, SAP, Magento</li>
        </ul>*/}
      </>
    ),
    icon: "yä",
  },
 

  
];

const OffersContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  padding: 0;
  height: 70%;
  font-weight: 400;
  font-size: 20px;
  color: white;
  line-height: 28px;
  display: grid;
  z-index: 10;
  justify-content: center;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
  }

  @media (min-width: 1350px) {
    grid-template-columns: repeat(2, 1fr);
    width: 100%;

    :nth-child(4n + 1) {
      justify-self: flex-start;
    }

    :nth-child(4n + 2) {
      justify-self: flex-end;
    }

  }
`;

const Container = styled.div`
  position: relative;
  width: calc(100% - 1px);
  background-size: cover;
  display: block;
  padding-top: 0;
  padding-bottom: 0;
  left: 0;
  height: 2900px;

  @media (min-width: 600px) {
    height: 2750px;
  }

  @media (min-width: 768px) {
    height: 1700px;
  }

  @media (min-width: 1350px) {
    height: 1100px;
  }

  @media (min-width: 1500px) {
    height: 975px;
  }
`;

const ImageBackgroundContainer = styled.div`
  width: 100.1%;
  height: 70.1%;
  position: absolute;
  overflow: hidden;
  z-index: 2;
`;

const ImagePlaceholder = styled.div`
  height: 100%;
  width: 100%;
  z-index: 1;
  position: absolute;
  visibility: visible;
`;

const Image = styled.div`
  background-image: url(${sweden});
  background-position: center center;
  background-repeat: no-repeat;
  transform: translate3d(0px, 159.156px, 0px) scale(1.005, 1.005);
  height: 800px;
  top: auto;
  bottom: 0;
  background-attachment: scroll;
  background-size: cover;
  position: absolute;
  z-index: 1;
  width: 100%;
`;

const Gradient = styled.div`
  background: rgb(9,56,42);
  background: linear-gradient(90deg, rgba(9,56,42,1) 25%, rgba(2,32,24,1) 74%);

  opacity: 0.96;
  width: 100%;
  height: 100%;
  z-index: 2;
  backface-visibility: hidden;
  position: absolute;
`;

const Offers = styled.div`
  width: 100%;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
`;

const Wrapper = styled.div`
  padding-bottom: 0px;
  position: relative;
  z-index: 1;
  overflow: visible;
  background: #073427;

  :before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    background: inherit;
    z-index: -1;
    top: 0;
    transform-origin: left top;
    transform: skewY(-1.8deg);
  }
`;

const Title = styled.div`
  font-size: 50px;
  font-weight: 700;
  padding-top: 40px;
  line-height: 1em;
  color: white;
  padding-left: 20px;
  padding-bottom: 20px;
  text-align: center;
`;

const Section = styled.section``;

const Offerings = () => {
  return (
    <>
      <Separator title={"Erbjudande"} />
      <Section id="offerings">
        <div>
          <Container>
            <ImageBackgroundContainer>
              <ImagePlaceholder>
                <Image />
              </ImagePlaceholder>
              <Gradient />
            </ImageBackgroundContainer>
            <Offers>
              <OffersContainer>
                {offerings.map((x) => (
                  <Offer
                    id={x.title}
                    key={x.description}
                    description={x.description}
                    title={x.title}
                    icon={x.icon}
                  />
                ))}
              </OffersContainer>
            </Offers>
          </Container>
        </div>
      </Section>
    </>
  );
};

const OfferWrapper = styled.div`
  flex: 1;
  display: flex;
  padding: 4%;

  &:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }
`;

const OfferTitle = styled.div`
  text-align: center;
  width: 100%;
  font-weight: 700;
  font-size: 32px;
  margin: 30px 0;
`;

const OfferDescription = styled.div`
  text-align: center;
  font-weight: 300;
  font-size: 16px;
`;
const OfferIcon = styled.div`
  width: 100%;
  text-align: center;
  font-size: 40px;
  margin-top: 30px;

  @media (max-width: 768px) {
    margin-top: 0px;
  }
`;

const Offer = ({ title, description, icon }) => {
  return (
    <OfferWrapper>
      <div className="body" style={{ width: "100%" }}>
        <div>
          <OfferIcon>
            <FontAwesomeIcon icon={["fas", icon]} />
          </OfferIcon>
          <OfferTitle>{title}</OfferTitle>
          <OfferDescription>{description}</OfferDescription>
        </div>
      </div>
    </OfferWrapper>
  );
};

export default Offerings;
