import React from "react";
import styled from "@emotion/styled";

const Wrapper = styled.div`
  padding-bottom: 45px;
  position: relative;
  z-index: 1;
  overflow: visible;
  background: #073427;

  :before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    background: inherit;
    z-index: -1;
    top: 0;
    transform: skewY(-1.8deg);
  }
`;

const DownWardsLeft = styled(Wrapper)`
  :before {
    transform-origin: left top;
  }
`;

const DownWardsRight = styled(Wrapper)`
  :before {
    transform-origin: right top;
    transform: skewY(1.8deg);
  }
`;

const Title = styled.div`
  font-size: 50px;
  font-weight: 700;
  padding-top: 40px;
  line-height: 1em;
  color: white;
  padding-left: 20px;
  padding-bottom: 20px;
  text-align: center;
`;

const Separator = ({ title, downwardsLeft = true }) => {
  if (downwardsLeft) {
    return (
      <DownWardsLeft>
        <Title>{title}</Title>
      </DownWardsLeft>
    );
  }

  return (
    <DownWardsRight>
      <Title>{title}</Title>
    </DownWardsRight>
  );
};

export default Separator;
